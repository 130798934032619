// template
require('./js/menu-tooltips');
require('./js/main-slideshow');
require('./js/testimonials');
require('./js/lightbox');
require('./js/responsive-tables');
require('./js/contacts-contact-form');

// Styles
require('./scss/index.sass');

// Fonts

require('./fonts/stylesheet.css');

// Django CMS Refresh helper
// https://github.com/divio/django-cms/issues/6273
require('./js/djangocms-reload');

