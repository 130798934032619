jQuery(document).ready(function () {

    jQuery(document).on('click', function (ev) {
        if (!jQuery(ev.target).parents('.popover').length && !jQuery(ev.target).parents('.testimonial-teaser-item').length) {
            jQuery('.testimonial-teaser-item').popover('hide');
        }
    });

    jQuery('.testimonial-teaser-item').each(function () {
        jQuery(this).click(function (event) {
            event.preventDefault();
            jQuery('.testimonial-teaser-item').not(this).popover('hide');
        });

        jQuery(this).popover({
            html: true,
            trigger: 'click',
            placement: 'left',
            fallbackPlacement: ['left', 'bottom', 'top']
        })
    });
});
