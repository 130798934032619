jQuery(document).ready(function () {

    const mainSlideshowElement = jQuery('.main-slideshow-container');

    const mainSlideshow = new Slideshow(
        mainSlideshowElement.find('.main-slideshow-item')
    );

    mainSlideshowElement.find('.back').on('click', function (e) {
        e.preventDefault();
        mainSlideshow.previous();
    });
    mainSlideshowElement.find('.play').on('click', function (e) {
        e.preventDefault();
        mainSlideshow.start();
        jQuery(this).css({'font-weight': 'bold'});
    });
    mainSlideshowElement.find('.stop').on('click', function (e) {
        e.preventDefault();
        mainSlideshow.stop();
        mainSlideshowElement.find('.play').css({'font-weight': 'normal'});
    });
    mainSlideshowElement.find('.next').on('click', function (e) {
        e.preventDefault();
        mainSlideshow.next();
    });

});

//slideshow object
function Slideshow(items) {

    jQuery(items).hide();

    var runId = 0;
    var imagePosition = 0;

    if (Cookies.get('imgPosition')) {
        imagePosition = parseInt(Cookies.get('imgPosition'));
        if (imagePosition < items.length - 1) {
            // make it rotate on each page load
            Cookies.set('imgPosition', imagePosition + 1, {path: '/'});
        }
        else {
            Cookies.set('imgPosition', 0, {path: '/'});
        }
    }
    else {
        Cookies.set('imgPosition', 1, {path: '/'});
    }

    jQuery(items[imagePosition]).show();

    function callMethod(thisObject) {
        return (function () {
            thisObject.next();
        });
    }

    this.next = function () {
        if (imagePosition === false) {
            imagePosition = 0;
        }
        jQuery(items[imagePosition]).fadeOut();

        if (imagePosition < items.length - 1) {
            imagePosition = ++imagePosition;
        } else {
            imagePosition = 0;
        }

        jQuery(items[imagePosition]).fadeIn();
        return imagePosition;
    };

    this.previous = function () {
        if (imagePosition === false) {
            imagePosition = 0;
        }
        jQuery(items[imagePosition]).fadeOut();
        if (imagePosition > 0) {
            imagePosition = --imagePosition;
        } else {
            imagePosition = items.length - 1;
        }
        jQuery(items[imagePosition]).fadeIn();
        return imagePosition;
    };

    this.start = function () {
        var funcRef = callMethod(this);
        runId = setInterval(funcRef, 5000);
    };

    this.stop = function () {
        clearInterval(runId);
    };
    return this;
}
