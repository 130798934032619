function create_uuid() {
    // Source: https://www.w3resource.com/javascript-exercises/javascript-math-exercise-23.php
    var dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

jQuery("form.contact-form").submit(function (ev) {
    ev.preventDefault();

    window.CONTACTS_DATA = window.CONTACTS_DATA || {};

    jQuery('.success-message, .error-message').addClass('d-none');

    var form = jQuery(this);
    var url = "/api/contacts/";
    var form_data = new FormData(form[0]);

    form.find('.spinner').toggleClass('d-none');

    // for ([key, value] of data) {
    //    console.log(key, value);
    // }

    form.find('button').attr("disabled", "disabled");
    form_data.append('data', JSON.stringify(window.CONTACTS_DATA));
    form_data.append('tracking_id', create_uuid());

    setTimeout(function () {
        // if the request completes too quickly the spinner is not visible
        jQuery.ajax({
            url: url,
            type: 'post',
            data: form_data,
            // https://stackoverflow.com/questions/6974684/how-to-send-formdata-objects-with-ajax-requests-in-jquery
            processData: false,
            contentType: false,
        }).done(function (data) {
            // success
            console.log('the form has been sent.');
            jQuery('.success-message').removeClass('d-none');

            // GA Event
            dataLayer.push({
                'event': 'manualgaevent',
                'category': 'Conversion',
                'action': 'Contact',
                'label': form_data.get('tracking_id'),
            });

            // GA Transaction
            dataLayer.push({
                'event': 'gatransaction',
                'transactionId': form_data.get('tracking_id'),
                'transactionAffiliation': '',
                'transactionTotal': 0,
                'transactionTax': 0,
                'transactionShipping': 0,
                'transactionProducts': [{
                    'sku': '0',
                    'name': 'Contact',
                    'category': 'Conversion',
                    'price': 0,
                    'quantity': 1
                }]
            });

            form.hide();
            jQuery("html, body").animate({scrollTop: 0}, "slow");
        }).fail(function (jqXHR, textStatus, errorThrown) {
            Raven.setUserContext({
                email: form_data.get('email'),
                extra: {
                    'email': form_data.get('email'),
                    'phone': form_data.get('phone'),
                    'comments': form_data.get('comments'),
                    'name': form_data.get('name')
                }
            });
            Raven.captureException(new Error('Contact form failed to post: ' + errorThrown));
            jQuery('.error-message').removeClass('d-none');
        }).always(function (data) {
            jQuery(form).find('button').removeAttr("disabled");
            form.find('.spinner').toggleClass('d-none');
        });
    }, 500);
});
